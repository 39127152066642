/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setFilterCategories } from "../../redux/actions/filters";

import { Language } from "./utils";

import ANPC from "../../assets/anpc.png";

const Header = () => {
    const dispatch = useDispatch();
    const dictionary = Language.getDictionary();
    const categories = useSelector(state => state.categories.data);
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (
        <footer>
            <div className={isMobile ? "d-flex flex-column justify-content-center text-center gap-3" : "d-flex flex-row justify-content-center"}>
                <div className="d-flex flex-column justify-content-start m-auto mt-0">
                    {/* <div className="color--black">Be part of the journey</div>
                    <div className="color--gray">Subscribe to be the first known about our latest merch drops and offers</div>
                    <div className="newsletter__input">
                        <input type="text" placeholder="Your email address goes here..." />
                        <div className="newsletter__input__button">Subscribe</div>
                    </div>
                    <br/> */}
                    <div className="color--black">{dictionary.footer_safe_shopping}</div>
                    <div className="d-flex flex-column justify-content-center gap-3">
                        <img className={isMobile ? "payments__icon m-auto" : "payments__icon"} src="https://imgur.com/7hiHm7M.png" alt="Safe pay methods." />
                        <a href="https://reclamatiisal.anpc.ro/">
                            <img className="anpc" src={ANPC} alt="Safe pay methods." />
                        </a>
                        {/* <a href="https://www.librapay.ro" title="Plati online" target="_blank"><img src="https://www.librapay.ro/images/icon_securitate_LibraPay_180x192px.jpg" alt="Icon securitate LibraPay" border="0" /></a>  */}
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-start m-auto mt-0">
                    <div className="color--black">{dictionary.footer_useful_links}</div>
                    <Link to="/contact" className="color--gray">{dictionary.footer_useful_links_contact_us}</Link>
                    <Link to="/about">{dictionary.footer_useful_links_about_us}</Link>
                    <Link to="/size-guide" className="color--gray">{dictionary.footer_useful_links_size_guide}</Link>
                    <Link to="/custom-merch">{dictionary.footer_useful_links_custom_merch}</Link>
                    <Link to="/design-gallery">{dictionary.footer_useful_links_design_gallery}</Link>
                </div>
                <div className="d-flex flex-column justify-content-start m-auto mt-0">
                    <div className="color--black">{dictionary.footer_policies}</div>
                    <Link to="/privacy" className="color--gray">{dictionary.footer_policies_privacy_policy}</Link>
                    <Link to="/tos" className="color--gray">{dictionary.footer_policies_terms_of_service}</Link>
                    <Link to="/return" className="color--gray">{dictionary.footer_policies_return_refund_policy}</Link>
                    <Link to="/shipping" className="color--gray">{dictionary.footer_policies_shipping_policy}</Link>
                </div>
                <div className="d-flex flex-column justify-content-start m-auto mt-0">
                    <div className="color--black">{dictionary.footer_categories}</div>
                    {
                        categories.filter(value => value.type == "primary").map((category, index) => (
                            <Link key={index} to={`/products`} className="choice"  onClick={() => dispatch(setFilterCategories([category]))}>{category.title}</Link>
                        ))
                    }
                </div>
            </div>

            <div className="copyright">
                <div className="text-center">
                    Copyright &copy; 2023 SUFYi - All rights reserved.
                </div>
            </div>
        </footer>
    );
}

export default Header;