/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setFilterCategories,
} from "../../redux/actions/filters";

import { Language } from "./utils";

const NavChoiceDropdown = ({ title, choices, close }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  return (
    <div className="nav__menu__choice">
      {
        show ?
          <>
            <div className="d-flex flex-row justify-content-between mx-4 my-2" onClick={() => setShow(!show)}>
              <div className="nav__menu__choice__title--open fs-6 w-fit">
                <img
                  src={require("../../assets/products.png").default}
                  alt="Products"
                  className="nav__menu__icon"
                />
                {title}
              </div>
              <span className="nav__menu__choice__icon--open material-icons-outlined">keyboard_arrow_up</span>
            </div>
            <div className="d-flex flex-column pb-3 ps-4 gap-3 border-bottom nav__menu__choice__content">
              {choices.map((choice, index) => (
                <Link
                  key={index}
                  to={`/products`}
                  onClick={(e) => { dispatch(setFilterCategories([choice])); close() }}
                >
                  {choice?.title}
                </Link>
              ))}
            </div>
          </>
          :
          <div className="d-flex flex-row justify-content-between border-bottom py-2 px-4" onClick={() => setShow(!show)}>
            <div className="fs-6 w-fit nav__menu__choice__title--close">
              <img
                src={require("../../assets/products.png").default}
                alt="Products"
                className="nav__menu__icon"
              />
              {title}
            </div>

            <span className="nav__menu__choice__icon--close material-icons-outlined">keyboard_arrow_down</span>
          </div>
      }

    </div>
  );
};

const NavMenu = ({ show, onClose }) => {
  const categories = useSelector((state) => state.categories.data);
  const dictionary = Language.getDictionary();

  const handleLanguage = (e) => {
    e?.preventDefault();
    Language.setLanguage(Language.getLanguage() == "en" ? "ro" : "en");
    window.location.reload();
  };

  return show ? (
    <div className="nav__menu">
      <div
        className="nav__menu__overlay"
        id="nav__menu__overlay"
        onClick={onClose}
      ></div>

      <div className="nav__menu__content" id="nav__menu__content">
        <div className="d-flex flex-row justify-content-between p-3 border-bottom py-0 pt-3">
          <h1 className="fs-6 text-uppercase text-black m-0">{dictionary.phone_menu_title}</h1>
          <img
            src={require("../../assets/close-o.svg").default}
            alt="Close"
            className="nav__menu__close"
            onClick={onClose}
          />
        </div>

        <div className="d-flex flex-row justify-content-start p-3 border-bottom gap-2">
          {
            Language.getFlags() && Object.entries(Language.getFlags()).map(([key, flag]) => (
              <div className="nav__button" onClick={handleLanguage}>
                <img
                  className={`nav__button__img ${Language.getLanguage() == key ? "flag__menu flag__menu--active" : "flag__menu"}`}
                  src={flag}
                  alt="Language flag."
                />
              </div>
            ))
          }
        </div>

        <NavChoiceDropdown title={dictionary.phone_menu_option_shop_by_type} choices={categories.filter((value) => value.type == "primary")} close={onClose} />
        <NavChoiceDropdown title={dictionary.phone_menu_option_shop_by_anime} choices={categories.filter((value) => value.pid == 4)} close={onClose} />

        <div className="w-100 border-bottom pb-2 ps-4">
          <Link className="fs-6 w-fit" to="/custom-merch" onClick={() => onClose()}>
            <img
              src={require("../../assets/tags.png").default}
              alt="Customize"
              className="nav__menu__icon"
            />
            {dictionary.phone_menu_option_customize_your_outfit}
          </Link>
        </div>
        <div className="w-100 border-bottom pb-2 ps-4">
          <Link className="fs-6 w-fit" to="/design-gallery" onClick={() => onClose()}>
            <img
              src={require("../../assets/tags.png").default}
              alt="Customize"
              className="nav__menu__icon"
            />
            {dictionary.phone_menu_option_design_gallery}
          </Link>
        </div>
        <div className="w-100 border-bottom pb-2 ps-4">
          <Link className="fs-6 w-fit" to=
            {
              localStorage.getItem("visitor") == "false" ?
                "/account"
                :
                "/login"
            } onClick={() => onClose()}>
            <img
              src={require("../../assets/account.png").default}
              alt="Log In"
              className="nav__menu__icon"
            />
            {
              localStorage.getItem("visitor") == "false" ?
                dictionary.phone_menu_option_my_account
                :
                dictionary.phone_menu_option_log_in
            }
          </Link>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default NavMenu;
