/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { setFilterAvailability, setFilterCategories, setFilterPriceRange } from "../../redux/actions/filters";

import { Language } from "./utils";

const NavFilter = ({ show, onClose }) => {
    const dictionary = Language.getDictionary();

    const categories = useSelector(state => state.categories.data);
    const filters = useSelector(state => state.filters);
    const products = useSelector(state => state.products.data);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setFilterPriceRange([0, Math.max(...products.map(p => p.price))]))
    }, [products])

    return (
        show ?
            <div className="nav__menu">
                <div className="nav__menu__overlay" id="nav__menu__overlay" onClick={onClose}></div>

                <div className="nav__menu__content" id="nav__menu__content">
                    <div className="d-flex flex-row justify-content-between p-3 border-bottom py-0 pt-3">
                        <h1 className="fs-6 text-uppercase text-black m-0">{dictionary.filter_title}</h1>
                        <img src={require("../../assets/close-o.svg").default} alt="Close" className="nav__menu__close" onClick={onClose} />
                    </div>

                    <div className="nav__menu__title fs-6 w-fit">{dictionary.filter_availability}</div>

                    <div className="d-flex flex-row gap-3 p-3 py-0 pointer">
                        {
                            filters?.availability?.in_stock ?
                                <span className="material-icons-outlined" onClick={() => dispatch(setFilterAvailability({ ...filters.availability, "in_stock": false }))}>check_box</span>
                                :
                                <span className="material-icons-outlined" onClick={() => dispatch(setFilterAvailability({ ...filters.availability, "in_stock": true }))}>check_box_outline_blank</span>
                        }
                        <label>{dictionary.filter_availability_in_stock}</label>
                    </div>

                    <div className="d-flex flex-row gap-3 p-3 py-0 pointer">
                        {
                            filters?.availability?.out_stock ?
                                <span className="material-icons-outlined" onClick={() => dispatch(setFilterAvailability({ ...filters.availability, "out_stock": false }))}>check_box</span>
                                :
                                <span className="material-icons-outlined" onClick={() => dispatch(setFilterAvailability({ ...filters.availability, "out_stock": true }))}>check_box_outline_blank</span>
                        }
                        <label>{dictionary.filter_availability_out_of_stock}</label>
                    </div>

                    <div className="d-flex flex-column justify-content-between p-3">
                        <div className="nav__menu__title fs-6 w-fit m-0">{dictionary.filter_price_range}</div>
                        <div className="text-black">
                            <span className="bold">{Number(filters?.priceRange[0])?.toLocaleString("ro-RO", { style: "currency", currency: "RON" })}</span> - <span className="bold">{Number(filters?.priceRange[1])?.toLocaleString("ro-RO", { style: "currency", currency: "RON" })}</span>
                        </div>
                    </div>


                    <div className="d-flex flex-column gap-3 p-3 py-0 pb-4 border-bottom">
                        <RangeSlider id="price__filter" value={filters?.priceRange} onInput={value => dispatch(setFilterPriceRange(value))} min={0} max={Math.max(...products.map(p => p.price))} defaultValue={[0, Math.max(...products.map(p => p.price))]} />
                    </div>

                    <div className="nav__menu__title fs-6 w-fit">{dictionary.filter_categories}</div>

                    <div className="filter p-3 py-0 pb-4 border-bottom pointer">
                        <div className="orderby">
                            <div className="d-flex flex-column gap-2">
                                {
                                    categories.filter(category => category.type == "primary").map((value, idx) => (
                                        filters?.categories?.find(cat => cat.id == value.id) ?
                                            <div key={idx} className="d-flex flex-row gap-3" onClick={() => dispatch(setFilterCategories([...filters?.categories?.filter(cat => cat.id != value.id)]))}>
                                                <span className="material-icons-outlined">check_box</span>

                                                <div className="d-flex flex-column gap-2 ms-0">
                                                    <label>{value.title}</label>
                                                </div>
                                            </div>
                                            :
                                            <div key={idx} className="d-flex flex-row gap-3" onClick={() => dispatch(setFilterCategories([...filters?.categories?.filter(cat => cat.id != value.id), value]))}>
                                                <span className="material-icons-outlined">check_box_outline_blank</span>

                                                <div className="d-flex flex-column gap-2 ms-0">
                                                    <label>{value.title}</label>
                                                </div>
                                            </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                    {
                        categories.filter(category => category.type == "secondary").map((value, idx) => (
                            <>
                                <div className="nav__menu__title fs-6 w-fit">{value.title}</div>

                                <div className="filter p-3 py-0 pb-4 border-bottom">
                                    <div className="orderby">
                                        <div className="d-flex flex-column gap-2">
                                            {
                                                categories.filter(category => category.pid == value.id).map((value, idx) => (
                                                    filters?.categories?.find(cat => cat.id == value.id) ?
                                                        <div key={idx} className="d-flex flex-row gap-3 pointer" onClick={() => dispatch(setFilterCategories([...filters?.categories?.filter(cat => cat.id != value.id)]))}>
                                                            <span className="material-icons-outlined">check_box</span>

                                                            <div className="d-flex flex-column gap-2 ms-0">
                                                                <label>{value.title}</label>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div key={idx} className="d-flex flex-row gap-3 pointer" onClick={() => dispatch(setFilterCategories([...filters?.categories?.filter(cat => cat.id != value.id), value]))}>
                                                            <span className="material-icons-outlined">check_box_outline_blank</span>

                                                            <div className="d-flex flex-column gap-2 ms-0">
                                                                <label>{value.title}</label>
                                                            </div>
                                                        </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))
                    }
                </div>
            </div>
            :
            <></>
    );
}

export default NavFilter;
