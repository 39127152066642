/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from 'react-helmet';

import { Layout, Language } from "../common/utils";
import { PageHeader } from '../common';

import { fetchPromo } from '../../redux/actions/promo';
import { checkout } from '../../redux/actions/checkout';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const PromoCode = () => {
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const cart = useSelector((state) => state.cart.data);
  const products = useSelector((state) => state.products.data);

  const [promoCode, setPromoCode] = useState("");
  const dictionary = Language.getDictionary();

  return (
    <div
      className={
        isMobile ? "m-auto w-100 input__group" : "m-auto input__group w-50"
      }
    >
      <h1>{dictionary.use_promo_title}</h1>

      <div>
        <label>{dictionary.promotional_code_label}</label>
        <div className="d-flex flex-row justify-content-start gap-5">
          <input
            className="w-25"
            type="text"
            placeholder={dictionary.promo_code_placeholder}
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
          />
          <div
            className="button w-25"
            onClick={() => {
              let ok = true;

              cart.forEach((item) => {
                const product = products.find(
                  (product) => product.id === item.id
                );

                if (product?.promo > 0) {
                  ok = false;
                }
              });

              if (!ok) {
                alert(dictionary.promo_code_error);
                return;
              }

              dispatch(fetchPromo(promoCode));
            }}
          >
            {dictionary.apply_button}
          </div>
        </div>
      </div>
    </div>
  );
};

const Index = () => {
    const dispatch = useDispatch();
    const dictionary = Language.getDictionary();
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    const promo = useSelector(state => state.promo.data);
    const cart = useSelector(state => state.cart.data);
    const products = useSelector(state => state.products);
    const account = useSelector(state => state.account.data);
    const page = useSelector(state => state.page);

    const [types, setTypes] = useState({});

    const [totalPrice, setTotalPrice] = useState(0);
    const [deliveryMethod, setDeliveryMethod] = useState('curier');
    const [deliveryPrice, setDeliveryPrice] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState("librapay");

    const [prenume, setPrenume] = useState(account?.firstname);
    const [nume, setNume] = useState(account?.lastname);
    const [email, setEmail] = useState(account?.email);
    const [telefon, setTelefon] = useState(account?.phone);
    const [adresa, setAdresa] = useState('');
    const [tara, setTara] = useState('');
    const [judet, setJudet] = useState('');
    const [localitate, setLocalitate] = useState('');
    const [observatii, setObservatii] = useState('');
    const [TOS, setTOS] = useState(false);

    useEffect(() => {
        if (deliveryMethod === 'curier') {
            setDeliveryPrice(25);
        } else {
            setDeliveryPrice(20);
        }
    }, [deliveryMethod]);

    useEffect(() => {
        setNume(account?.lastname);
        setPrenume(account?.firstname);
        setEmail(account?.email);
        setTelefon(account?.phone);
    }, [account]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!products.inLoading) {
            let totalPrice = 0;

            cart?.forEach((item, index) => {
                const product = products?.data?.find((product) => product?.id === item?.id) || {
                    id: item?.id,
                    price: types?.[item?.content?.category]?.[item?.content?.sex]?.[item?.content?.type]?.price,
                    promo: 0,
                    content: item?.content
                }

                if (product !== undefined) {
                    if (product.promo > 0) {
                        totalPrice += item.amount * Math.floor((product.price - product.price * product.promo / 100) * 100) / 100;
                    } else {
                        totalPrice += item.amount * product.price;
                    }
                }
                else {
                    const content = item.content;
                    totalPrice += item.amount * types?.[content.category]?.[content.type]?.price;
                }
            })

            setTotalPrice(totalPrice);
        }
    }, [products.inLoading, page.inLoading, cart, types]);

    useEffect(() => {
        if (!page.inLoading) {
            setTypes(getProductTypes());
        }
    }, [page.inLoading]);

    useEffect(() => {
        window.dataLayer.push({
            'event': 'add_payment_info'
        });
        window.dataLayer.push({
            'event': 'add_shipping_info'
        });
    }, []);

    const getPageParameter = (parameter) => {
        for (const param of page?.data) {
            if (param.name === parameter) {
                return param.type == "integer" ? Number(param.value) : param.value;
            }
        }

        return null;
    }

    const getProductTypes = () => {
        return JSON.parse(getPageParameter("product_types"));
    }

    return (
        <>
            <Helmet>
            <title>{dictionary.checkout}</title>
            </Helmet>
            <PageHeader page="Checkout" />
            <Layout>
                {
                    cart?.length > 0 ?
                        <>
                            <div className={isMobile ? 'm-auto w-100' : 'm-5 m-auto w-100'}>
                                <div className='m-auto w-100 input__group'>
                                <h1>{dictionary.billing}</h1>

                                    <div className="checkout">
                                        {
                                            cart.map((item, index) => {
                                                let product;

                                                if (item.content.custom) {
                                                  product = {
                                                    price: types?.[item?.content?.category]?.[item?.content?.sex]?.[item?.content?.type]?.price,
                                                    promo: 0,
                                                    title: types?.[item?.content?.category]?.[item?.content?.sex]?.[item?.content?.type]?.name,
                                                    content: item?.content,
                                                    custom: true
                                                  }
                                                }
                                                else {
                                                  product = products?.data?.find((product) => product?.id === item?.id);
                                                }

                                                return (
                                                    product &&
                                                    <div className="checkout__item" key={index}>
                                                        {
                                                            item.content.custom ?
                                                            <div className="checkout__item__name">
                                                                Custom {product?.title} {item?.content?.sex} {item?.content?.color} {item.content.size.name} {item.amount} pcs.
                                                            </div>
                                                            :
                                                            <div className="checkout__item__name">
                                                                {product.title} {item.content.color} {item.content.size} {item.amount} pcs.
                                                            </div>
                                                        }

                                                        <div className="checkout__item__price">
                                                            {
                                                                product?.content?.promo > 0 ?
                                                                    <>
                                                                        <strike>{(item.amount * product.price).toLocaleString("ro-RO", { style: "currency", currency: "RON" })}</strike> <span className="promo"> - {JSON.parse(product.content)?.promo}%</span> {(Math.floor(((item.amount * product.price) - (item.amount * product.price) * product.content?.promo / 100) * 100) / 100).toLocaleString("ro-RO", { style: "currency", currency: "RON" })}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {(item.amount * product.price).toLocaleString("ro-RO", { style: "currency", currency: "RON" })}
                                                                    </>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                        {
                                            JSON.stringify(promo) != "{}" && typeof promo == "object" &&
                                            <div className="checkout__item">
                                                <div className="checkout__item__name">
                                                    PROMO {promo?.name} - {promo.value}%
                                                </div>
                                                <div className="checkout__item__price">
                                                    -{((promo.value * totalPrice) / 100).toLocaleString("ro-RO", { style: "currency", currency: "RON" })}
                                                </div>
                                            </div>
                                        }

                                        <div className="checkout__item">
                                            <div className="checkout__item__name">
                                                {dictionary.shipping_product} {deliveryMethod == "curier" ? "Fan Courier" : "Easybox"}.
                                            </div>

                                            <div className="checkout__item__price">
                                                +{(deliveryPrice).toLocaleString("ro-RO", { style: "currency", currency: "RON" })}
                                            </div>
                                        </div>
                                        <div className="checkout__item">
                                            <div className="checkout__item__name">
                                                Total
                                            </div>

                                            <div className="checkout__item__price">

                                                {
                                                    JSON.stringify(promo) != "{}" && typeof promo == "object" ?
                                                        <>
                                                            {(totalPrice + deliveryPrice - (promo?.value * totalPrice) / 100).toLocaleString("ro-RO", { style: "currency", currency: "RON" })}
                                                        </>
                                                        :
                                                        <>
                                                            {(totalPrice + deliveryPrice).toLocaleString("ro-RO", { style: "currency", currency: "RON" })}
                                                        </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <PromoCode />

                                <div className='m-auto w-100 input__group'>
                                    <h1>{dictionary.shipping_form}</h1>

                                    {
                                        localStorage.getItem('visitor') == "true" &&
                                        <>
                                                <div>
                                                    <label>{dictionary.first_name}</label>
                                                    <input
                                                        type="text"
                                                        placeholder="..."
                                                        value={prenume}
                                                        onChange={e => setPrenume(e.target.value)}
                                                    />
                                                </div>
                                                <div>
                                                    <label>{dictionary.last_name}</label>
                                                    <input
                                                        type="text"
                                                        placeholder="..."
                                                        value={nume}
                                                        onChange={e => setNume(e.target.value)}
                                                    />
                                                </div>
                                                <div>
                                                    <label>{dictionary.phone_number}</label>
                                                    <input
                                                        type="text"
                                                        placeholder="..."
                                                        value={telefon}
                                                        onChange={e => setTelefon(e.target.value)}
                                                    />
                                                </div>
                                                <div>
                                                    <label>{dictionary.email}</label>
                                                    <input
                                                        type="text"
                                                        placeholder="..."
                                                        value={email}
                                                        onChange={e => setEmail(e.target.value)}
                                                    />
                                                </div>
                                        </>
                                    }

                                    <div className="d-flex flex-row justify-content-start gap-3 input__group w-100 p-0">
                                        <div className='w-100'>
                                            <label>{dictionary.payment_method}</label>
                                            <select className='w-100 py-3 px-5' onChange={e => setPaymentMethod(e.target.value)}>
                                                <option value="librapay">LibraPay</option>
                                            </select>
                                        </div>
                                        <div className='w-100'>
                                            <label>{dictionary.shipping_method}</label>
                                            <select className='w-100 py-3 px-5' onChange={e => setDeliveryMethod(e.target.value)}>
                                                <option value="fancourier">Fan Courier</option>
                                                {/* <option value="cargus">Cargus</option> */}
                                            </select>
                                        </div>
                                    </div>

                                    <div className='w-100'>
                                        <label>{dictionary.country}</label>
                                        <input
                                            type="text"
                                            placeholder="..."
                                            value={tara}
                                            onChange={e => setTara(e.target.value)}
                                        />
                                    </div>
                                    <div className='w-100'>
                                        <label>{dictionary.county}</label>
                                        <input
                                            type="text"
                                            placeholder="..."
                                            value={judet}
                                            onChange={e => setJudet(e.target.value)}
                                        />
                                    </div>
                                    <div className='w-100'>
                                        <label>{dictionary.city}</label>
                                        <input
                                            type="text"
                                            placeholder="..."
                                            value={localitate}
                                            onChange={e => setLocalitate(e.target.value)}
                                        />
                                    </div>
                                    <div className='w-100'>
                                        <label>{dictionary.address}</label>
                                        <input
                                            type="text"
                                            placeholder="..."
                                            value={adresa}
                                            onChange={e => setAdresa(e.target.value)}
                                        />
                                    </div>
                                    <div className='w-100'>
                                        <label>{dictionary.observations}</label>
                                        <input
                                            type="text"
                                            placeholder="..."
                                            value={observatii}
                                            onChange={e => setObservatii(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="d-flex flex-row justify-content-center gap-3 m-auto my-3">
                                    <div>
                                      <span
                                        className="material-icons-outlined icon"
                                        onClick={(e) => setTOS(!TOS)}
                                      >
                                        {TOS ? "check_box" : "check_box_outline_blank"}
                                      </span>
                                      {dictionary.read_tos} {" "}
                                      <Link to="/tos" className="text-primary">
                                        {dictionary.tos}
                                      </Link>
                                      .
                                    </div>
                                  </div>

                                <div className='d-flex flex-row justify-content-center gap-3 m-auto'>
                                    <div className="button p-3" onClick={
                                        () => {
                                            let produse = [];

                                             if (!TOS) {
                                               return alert("Terms of Services are mandatory for orders.");
                                             }

                                            if (nume == "" || prenume == "" || telefon == "" || email == "" || judet == "" || localitate == "" || adresa == "") {
                                                return alert("All the fields are mandatory!");
                                            }

                                            cart.forEach((item, index) => {
                                                const product = products?.data?.find((product) => product?.id === item?.id) || {
                                                    id: item?.id,
                                                    price: types?.[item?.content?.category]?.[item?.content?.sex]?.[item?.content?.type]?.price,
                                                    promo: 0,
                                                    content: item?.content
                                                }

                                                if (product) {
                                                    produse.push({
                                                        id: product.id,
                                                        title: product.title,
                                                        color: item.content.color,
                                                        size: item.content.size,
                                                        amount: item.amount,
                                                        price: product.price,
                                                        promo: product.content.promo ?? product.promo
                                                    })
                                                }
                                            })

                                            dispatch(checkout({
                                                firstname: nume,
                                                lastname: prenume,
                                                phone: telefon,
                                                email: email,
                                                country: tara,
                                                county: judet,
                                                city: localitate,
                                                address: adresa,
                                                obs: observatii,
                                                paymentMethod: paymentMethod,
                                                deliveryMethod: deliveryMethod,
                                                deliveryPrice: deliveryPrice,
                                                price: JSON.stringify(promo) != "{}" && typeof promo == "object" ? totalPrice + deliveryPrice - (promo?.value * totalPrice) / 100 : totalPrice + deliveryPrice,
                                                promo: promo,
                                                cart: produse
                                            }));
                                        }
                                    }>
                                        {dictionary.place_order}
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <center>
                            {dictionary.empty_cart} <Link className="text-danger" to="/products">{dictionary.check_products}</Link>.
                        </center>
                }
            </Layout>
        </>
    );
};

export default Index;