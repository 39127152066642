/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";

import { Header, Footer } from "../common";

import About from "./About";
import Account from "./Account";
import Checkout from "./Checkout";
import Contact from "./Contact";
import Configurator from "./Configurator";
import Edit from "./Edit";
import Home from "./Home";
import Insert from "./Insert";
import Login from "./Login";
import Promocodes from "./Promocodes";
import Order from "./Order";
import Orders from "./Orders";
import Privacy from "./Privacy";
import Product from "./Product";
import Products from "./Products";
import Register from "./Register";
import Return from "./Return";
import TOS from "./TOS";
import Shipping from "./Shipping";
import SizeGuide from "./SizeGuide";
import Gallery from "./Gallery";

import { checkIn, checkInVisitor } from "../../redux/actions/account";
import { fetchCart } from "../../redux/actions/cart";
import { fetchPage } from "../../redux/actions/page";
import { fetchProducts } from "../../redux/actions/products";
import { fetchWishlist } from "../../redux/actions/wishlist";
import { fetchCategories } from "../../redux/actions/categories";

const Application = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (localStorage.getItem("token") !== null && localStorage.getItem("visitor") !== "true") {
            dispatch(checkIn());
            dispatch(fetchCart());
            dispatch(fetchWishlist());
        }
        else {
            dispatch(checkInVisitor());
        }

        dispatch(fetchProducts());
        dispatch(fetchPage());
        dispatch(fetchCategories());
    }, []);

    return (
        <React.Fragment>
            <Header />

            <Switch>
                <Route exact path="/about">
                    <About />
                </Route>
                <Route exact path="/account">
                    <Account />
                </Route>
                <Route exact path="/contact">
                    <Contact />
                </Route>
                <Route exact path="/custom-merch">
                    <Configurator />
                </Route>
                <Route exact path="/design-gallery">
                    <Gallery />
                </Route>
                <Route exact path="/edit">
                    <Edit />
                </Route>
                <Route exact path="/privacy">
                    <Privacy />
                </Route>
                <Route exact path="/tos">
                    <TOS />
                </Route>
                <Route exact path="/login">
                    <Login />
                </Route>
                <Route exact path="/register">
                    <Register />
                </Route>
                <Route exact path="/return">
                    <Return />
                </Route>
                <Route exact path="/shipping">
                    <Shipping />
                </Route>
                <Route exact path="/insert">
                    <Insert />
                </Route>
                <Route exact path="/promocodes">
                    <Promocodes />
                </Route>
                <Route exact path="/products">
                    <Products />
                </Route>
                <Route exact path="/product/:id">
                    <Product />
                </Route>
                <Route exact path="/order">
                    <Order />
                </Route>
                <Route exact path="/order/:id">
                    <Order />
                </Route>
                <Route exact path="/Orders">
                    <Orders />
                </Route>
                <Route exact path="/checkout">
                    <Checkout />
                </Route>
                <Route exact path="/size-guide">
                    <SizeGuide />
                </Route>
                <Route exact path="/">
                    <Home />
                </Route>
            </Switch>

            <Footer />
        </React.Fragment>

    );
};

export default Application;