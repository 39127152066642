/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Layout, Language } from '../common/utils';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader, ProductCard } from '../common';
import NavFilter from '../common/NavFilter';
import { setFilterType } from '../../redux/actions/filters';

const ProductsHeader = () => {
    const dispatch = useDispatch();
    const [width, setWidth] = useState(window.innerWidth);
    const dictionary = Language.getDictionary(); // Get the dictionary for the current language

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;
    const filters = useSelector(state => state.filters);
    const [filter, setFilter] = useState(false);

    const options = [
        { value: 'none', label: dictionary['none'] || 'None' },
        { value: 'promo', label: dictionary['promo'] || 'On Promotion' },
        { value: 'new', label: dictionary['new'] || 'New Products' },
        { value: 'upprice', label: dictionary['upprice'] || 'Up by Price' },
        { value: 'downprice', label: dictionary['downprice'] || 'Down by Price' },
        { value: 'stock', label: dictionary['stock'] || 'Availability' }
    ]
    const handleToggle = () => {
        setFilter(!filter);
    }

    return (
        <div className="d-flex flex-row justify-content-between p-5 py-2 w-100">
            <NavFilter show={filter} onClose={handleToggle} />

            <div className='pointer w-100' onClick={handleToggle}><span className="icon material-icons-outlined">filter_list</span> {dictionary['filter'] || 'Filter'}</div>

            <div className={isMobile ? "filter" : "filter w-25"}>
                <div className="orderby">
                    <select
                        className='w-100'
                        value={filters?.type}
                        onChange={(e) => dispatch(setFilterType(e.target.value))}
                    >
                        {
                            options.map((value, idx) => (
                                <option key={idx} value={value.value}>{value.label}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
        </div>
    );
};

const Products = props => {
    const filters = useSelector(state => state.filters);

    const getFinalPrice = (price, promo) => {
        if (promo > 0) {
            return Math.floor((price - price * promo / 100) * 100) / 100
        }
        else {
            return price
        }
    }

    return (
        <div className="products">
            {
                (
                    filters?.type === 'upprice' ?
                        props?.list?.sort((elm1, elm2) => elm1.price - elm2.price)
                        :
                        filters?.type === 'downprice' ?
                            props?.list?.sort((elm1, elm2) => elm2.price - elm1.price)
                            :
                            filters?.type === 'stock' ?
                                props?.list?.sort((elm1, elm2) => elm2.stock - elm1.stock)
                                :
                                filters?.type === 'new' ?
                                    props?.list?.sort((elm1, elm2) => elm2.id - elm1.id)
                                    :
                                    filters?.type === 'promo' ?
                                        props?.list?.filter(elm => elm.promo > 0)
                                        :
                                        props?.list
                )
                    ?.filter((value) => filters?.availability?.in_stock == filters?.availability?.out_stock ? true : filters?.availability?.in_stock ? value.stock > 0 : value.stock == 0)
                    ?.filter((value) => filters?.priceRange?.length == 0 ? true : filters?.priceRange[0] <= getFinalPrice(value.price, value?.content?.promo) && filters?.priceRange[1] >= getFinalPrice(value.price, value?.content?.promo))
                    ?.filter((value) => filters?.categories?.length == 0 ? true : filters?.categories?.find(category => value.categories.find(valCategory => valCategory == category.id)) || filters?.categories?.find(category => value.categories.find(valCategory => valCategory == category.pid)))?.map(
                        (value, idx) => (
                            <ProductCard key={idx} product={value} />
                        )
                    )
            }
        </div>
    );
};

const Index = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const dictionary = Language.getDictionary(); // Get the dictionary for the current language

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;
    const products = useSelector(state => state.products);

    return (
        <>
            <Helmet>
                <title>{dictionary['our_products'] || 'Our Products'}</title>
            </Helmet>
            <PageHeader page={dictionary['our_products'] || 'Our Products'} />

            <Layout>
                <div className={isMobile ? "d-flex flex-column justify-content-between m-3 gap-4" : "d-flex flex-column justify-content-between m-5 gap-4"}>
                    <ProductsHeader />
                    <div className={'p-relative'}>
                        <Products list={products?.data} />
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default Index;