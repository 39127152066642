/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";

import { removeFromWishlist } from "../../redux/actions/wishlist";
import {addToCart} from "../../redux/actions/cart";

import { Language } from "./utils";

const NavWishlist = ({ show, onClose }) => {
    const dispatch = useDispatch();
    const dictionary = Language.getDictionary();

    const wishlist = useSelector(state => state.wishlist.data);
    const products = useSelector(state => state.products.data);
    const page = useSelector(state => state.page);

    const [types, setTypes] = useState({});

    useEffect(() => {
        if (!page.inLoading) {
            setTypes(getProductTypes());
        }
    }, [page.inLoading]);

    const getPageParameter = (parameter) => {
        for (const param of page?.data) {
            if (param.name === parameter) {
                return param.type == "integer" ? Number(param.value) : param.value;
            }
        }

        return null;
    }

    const getProductTypes = () => {
        return JSON.parse(getPageParameter("product_types"));
    }

    const getImageOfProduct = configurator => {
        let result;

        if (["clothes", "animalut"].includes(configurator.category)) {
            result = configurator.sex != "none" && configurator.type != "none" && configurator.face != "none" && configurator.color != "none" ? require(`../../assets/products/${configurator.category}/${configurator.sex}/${configurator.type}/${configurator.face}-${configurator.color}.png`).default : "https://imgur.com/bCqGeYH.png";
        }
        else if (["pack", "pillow"].includes(configurator.category)) {
            result = configurator.color != "none" ? require(`../../assets/products/${configurator.category}/${configurator.color}.png`).default : "https://imgur.com/bCqGeYH.png";
        }

        return result;
    }

    return (
        show ?
        <div className="nav__menu">
                    <div className="nav__menu__overlay" id="nav__menu__overlay" onClick={onClose}></div>

                    <div className="nav__menu__content" id="nav__menu__content">
                        <div className="d-flex flex-row justify-content-between p-3 border-bottom py-0 pt-3">
                            <h1 className="fs-6 text-uppercase text-black m-0">{dictionary.wishlist}</h1>
                            <img src={require("../../assets/close-o.svg").default} alt="Close" className="nav__menu__close" onClick={onClose} />
                        </div>

                        {
                            wishlist?.length > 0 ?
                            wishlist?.map((item, key) => {
                                let product;

                                if (item.content.custom) {
                                  product = {
                                    price: types?.[item?.content?.category]?.[item?.content?.sex]?.[item?.content?.type]?.price,
                                    images: [getImageOfProduct(item?.content), item?.content?.image],
                                    promo: 0,
                                    title: types?.[item?.content?.category]?.[item?.content?.sex]?.[item?.content?.type]?.name,
                                    content: item?.content,
                                    custom: true
                                  }
                                }
                                else {
                                  product = products?.find((product) => product?.id === item?.id);

                                  if (product === undefined) {
                                    return <></>;
                                  }
                                }
                                return (
                                    <div className="cart__product p-3" key={key}>
                                        <img
                                            className="cart__product__image"
                                            src={product.images[0]}
                                            alt={product.name}
                                            style={{
                                                "opacity": product.stock > 0 ? 1 : 0.5
                                            }} />
                                        {
                                            item.content.custom ?
                                            <div className="cart__product__info">
                                              <div>
                                                Custom {product?.title} {item?.content?.sex} {item?.content?.color}
                                              </div>
                                              <div>{item.content.size.name}</div>
                                            </div>
                                            :
                                            <div className="cart__product__info">
                                              <div>
                                                {product?.title} {item?.content?.color}
                                              </div>
                                              <div>{item.content.size}</div>
                                            </div>
                                          }

                                          <div className='d-flex flex-column gap-1 ms-2'>
                                            <div className="button" onClick={() => dispatch(addToCart(product.id, item.content, 1))}>Add to cart</div>
                                            <div className="button" onClick={() => dispatch(removeFromWishlist(product.id, item.content))}>Remove</div>
                                          </div>
                                    </div>
                                )
                            })
                            :
                            <div className="text-center my-4">
                                {dictionary.wishlist_empty}
                            </div>
                        }
                    </div>
                </div>
            :
            <></>
    );
}

export default NavWishlist;