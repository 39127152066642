import React from "react";
import { Layout, Language } from "../common/utils";
import { Helmet } from 'react-helmet';
import { PageHeader } from '../common';

const Index = () => {
    const dictionary = Language.getDictionary(); // Get the current language dictionary

    return (
        <>
            <Helmet>
                <title>{dictionary.size_guide}</title> {/* Dynamic title based on translation */}
            </Helmet>
            <PageHeader page={dictionary.size_guide} /> {/* Dynamic page header based on translation */}
            <Layout>
                <center>
                    <img src={"https://cdn.shopify.com/s/files/1/0585/5834/9490/files/WhatsApp_Image_2022-07-16_at_5.06.08_PM_480x480.jpg?v=1658218438"} alt={dictionary.size_guide} /> {/* Dynamic alt text for image */}
                    <div>
                        <h2>{dictionary.t_shirt}</h2> {/* Dynamic translation for "T-Shirt" */}
                        <table className="table border w-75">
                            <tbody>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">{dictionary.width_inch}</th> {/* Dynamic translation for "Width (inch)" */}
                                    <th scope="col">{dictionary.height_inch}</th> {/* Dynamic translation for "Height (inch)" */}
                                </tr>
                                <tr>
                                    <th scope="col">S</th>
                                    <th scope="col">23</th>
                                    <th scope="col">28</th>
                                </tr>
                                <tr>
                                    <th scope="col">M</th>
                                    <th scope="col">24</th>
                                    <th scope="col">28.5</th>
                                </tr>
                                <tr>
                                    <th scope="col">L</th>
                                    <th scope="col">25</th>
                                    <th scope="col">28.5</th>
                                </tr>
                                <tr>
                                    <th scope="col">XL</th>
                                    <th scope="col">25.5</th>
                                    <th scope="col">30</th>
                                </tr>
                                <tr>
                                    <th scope="col">XXL</th>
                                    <th scope="col">26</th>
                                    <th scope="col">30</th>
                                </tr>
                            </tbody>
                        </table>

                        <h2>{dictionary.hoodie_and_sweatshirt}</h2> {/* Dynamic translation for "Hoodie and Sweatshirt" */}
                        <table className="table border w-75">
                            <tbody>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">{dictionary.width_inch}</th> {/* Dynamic translation for "Width (inch)" */}
                                    <th scope="col">{dictionary.height_inch}</th> {/* Dynamic translation for "Height (inch)" */}
                                </tr>
                                <tr>
                                    <th scope="col">S</th>
                                    <th scope="col">24</th>
                                    <th scope="col">26</th>
                                </tr>
                                <tr>
                                    <th scope="col">M</th>
                                    <th scope="col">25</th>
                                    <th scope="col">26.5</th>
                                </tr>
                                <tr>
                                    <th scope="col">L</th>
                                    <th scope="col">25.5</th>
                                    <th scope="col">27</th>
                                </tr>
                                <tr>
                                    <th scope="col">XL</th>
                                    <th scope="col">27</th>
                                    <th scope="col">29</th>
                                </tr>
                                <tr>
                                    <th scope="col">XXL</th>
                                    <th scope="col">27.5</th>
                                    <th scope="col">31</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </center>
            </Layout>
        </>
    );
};

export default Index;
