/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteProduct } from "../../redux/actions/product";

const ProductCard = ({ product }) => {
    const dispatch = useDispatch();

    const account = useSelector(state => state.account.data)

    const handleMouseOnProduct = () => {
        window.dataLayer.push({'event': 'view_item', 'ecommerce': {
            'items': [{
                'id': product.id,
                'name': product.title,
                'price': product.price,
                'quantity': product.stock
            }]
        }});
    };

    return (
        <div className="product" style={{   
                "opacity": product.stock > 0 ? 1 : 0.5,
            }}
            onMouseEnter={handleMouseOnProduct}
        >
            <div className="image">
                <img className="main__image" src={product?.images && (product?.images[0])} alt="product" />
                <img className="sec__image" src={product?.images && (product?.images[1])} alt="product" />
                <div className="overlay">
                    <div className="menu">
                        <Link to={`/product/${product?.id}`} className="button">
                            <span className="icon material-icons-outlined">
                                visibility
                            </span>
                        </Link>

                        {
                            account?.type == "master" &&
                            <Link to={`/edit?id=${product?.id}`} className="button">
                                    <span className="icon material-icons-outlined">
                                        edit
                                    </span>
                            </Link>
                        }
                        {
                            account?.type == "master" &&
                            <div className="button" onClick={() => {
                                if (window.confirm("Are you sure you want to delete this product?")) {
                                    dispatch(deleteProduct({ id: product.id }));
                                }
                            }}>
                                <span className="icon material-icons-outlined">
                                    delete
                                </span>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <Link className="w-100" to={`/product/${product?.id}`}>
                <div className="title">
                    {product.title.length > 20 ? product.title.substring(0, 20) + "..." : product.title}
                </div>
                <div className="price">
                    {
                        product?.content?.promo > 0 ?
                            <div className="text-promo">
                                <strike>{product.price.toLocaleString("ro-RO", { style: "currency", currency: "RON" })}</strike> <span className="promo"> - {product.content?.promo}%</span> {(Math.floor((product.price - product.price * product.content?.promo / 100) * 100) / 100).toLocaleString("ro-RO", { style: "currency", currency: "RON" })}
                            </div>
                            :
                            <>
                                {product.price.toLocaleString("ro-RO", { style: "currency", currency: "RON" })}
                            </>
                    }
                </div>
            </Link>
        </div>
    )
};

export default ProductCard;